import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import JSZip from 'jszip';
import { Stack, CircularProgress, Select, MenuItem, Grid } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { frontendURL } from '../../services/var';

const categories = [
  {
    name: 'หมูกระทะ',
    sizes: [
      { size: '10 x 15 ซม.', url: 'pig.png' },
      { size: '10 x 10 ซม.', url: 'pig.png' },
      { size: '12 x 7.5 ซม.', url: 'pig.png' },
    ],
  },
  {
    name: 'คาเฟ่',
    sizes: [
      { size: '10 x 15 ซม.', url: 'cafe.png' },
      { size: '10 x 10 ซม.', url: 'cafe.png' },
      { size: '12 x 7.5 ซม.', url: 'cafe.png' },
    ],
  },
  {
    name: 'สเต็ก',
    sizes: [
      { size: '10 x 15 ซม.', url: 'stark.png' },
      { size: '10 x 10 ซม.', url: 'stark.png' },
      { size: '12 x 7.5 ซม.', url: 'stark.png' },
    ],
  },
  {
    name: 'โรงแรมรีสอร์ท',
    sizes: [
      { size: '10 x 15 ซม.', url: 'hotal.png' },
      { size: '10 x 10 ซม.', url: 'hotal.png' },
      { size: '12 x 7.5 ซม.', url: 'hotal.png' },
    ],
  },
  {
    name: 'ร้านขายของทั่วไป',
    sizes: [
      { size: '10 x 15 ซม.', url: 'gen.png' },
      { size: '10 x 10 ซม.', url: 'gen.png' },
      { size: '12 x 7.5 ซม.', url: 'gen.png' },
    ],
  },
  {
    name: 'ร้านเหล้า',
    sizes: [
      { size: '10 x 15 ซม.', url: 'alo.png' },
      { size: '10 x 10 ซม.', url: 'alo.png' },
      { size: '12 x 7.5 ซม.', url: 'alo.png' },
    ],
  },
  {
    name: 'ร้านแนวญี่ปุ่น',
    sizes: [
      { size: '10 x 15 ซม.', url: 'ja.png' },
      { size: '10 x 10 ซม.', url: 'ja.png' },
      { size: '12 x 7.5 ซม.', url: 'ja.png' },
    ],
  },
  {
    name: 'โรงพยาบาล',
    sizes: [
      { size: '10 x 15 ซม.', url: 'hotail.png' },
      { size: '10 x 10 ซม.', url: 'hotail.png' },
      { size: '12 x 7.5 ซม.', url: 'hotail.png' },
    ],
  },
  {
    name: 'อาหารจีน',
    sizes: [
      { size: '10 x 15 ซม.', url: 'ch.png' },
      { size: '10 x 10 ซม.', url: 'ch.png' },
      { size: '12 x 7.5 ซม.', url: 'ch.png' },
    ],
  },
  {
    name: 'อาหารไทย',
    sizes: [
      { size: '10 x 15 ซม.', url: 'thai.png' },
      { size: '10 x 10 ซม.', url: 'thai.png' },
      { size: '12 x 7.5 ซม.', url: 'thai.png' },
    ],
  },
];

function LinearProgressWithLabel(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {props.currentProgress}
      </Grid>
    </Grid>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  currentProgress: PropTypes.string.isRequired,
};

const Canvas = (props) => {
  const { auth, table } = useSelector((state) => state);
  const { tables } = table;
  const { enqueueSnackbar } = useSnackbar();
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });

  const [progress, setProgress] = useState(0);
  const [currentProgress, setCurrentProgress] = useState('');

  const [width, setWidth] = useState(1200);
  const [height, setHeight] = useState(1800);
  const [themeName, setThemeName] = useState('default');
  const [backgroundTheme, setBackgroundTheme] = useState('../assets/images/theme/full_4x6.png');
  const zip = new JSZip();

  const calculateTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = font;
    return ctx.measureText(text).width;
  };
  const [categoryTheme, setCategoryTheme] = useState('default');
  const [selectedSize, setSelectedSize] = useState({ size: 'default' }, { size: 'default', url: '' });

  const handleThemeBackgroundChange = (selectedCategory) => {
    setCategoryTheme(selectedCategory);
    setThemeName('default');
    setSelectedSize({ size: 'default' }, { size: 'default', url: '' });
    // Assuming you want to set the size to the first size in the selected category
    const selectedCategoryObject = categories.find((category) => category.name === selectedCategory);
    if (selectedCategoryObject && selectedCategoryObject.sizes.length > 0) {
      setThemeName('default');
      setSelectedSize({ size: 'default' }, { size: 'default', url: '' });
    } else {
      setThemeName('default');
      setSelectedSize({ size: 'default' }, { size: 'default', url: '' });
    }
  };
  const textFormat = (ctx, text, width, height, yTop, fontSize, lineheight, isBorder) => {
    const textWidth = calculateTextWidth(text, `${fontSize}px Prompt`);
    const inputString = `${fontSize} Prompt`;
    const numericPart = inputString.replace(/[^0-9]/g, '').trim();
    if (isBorder) {
      ctx.fillStyle = '#000000db';
      ctx.beginPath();
      ctx.roundRect((width - textWidth) / 2 - 25, yTop, textWidth + 60, parseInt(numericPart, 10) + 40, 20);
      ctx.fill();
    }
    ctx.font = `${fontSize}px Prompt`; // Set the font size and family
    ctx.fillStyle = 'white'; // Set the text color
    ctx.fillText(text, (width - textWidth) / 2, lineheight + yTop);
  };

  const textFormat2 = (ctx, text, width, height, yTop, fontSize, lineheight, isBorder, position) => {
    const textWidth = calculateTextWidth(text, `${fontSize}px Prompt`);
    if (isBorder) {
      ctx.fillStyle = '#000000db';
      ctx.beginPath();
      if (position === 'left') {
        ctx.roundRect(
          (width - textWidth) / 2 - 25,
          yTop - 5,
          textWidth + 60,
          parseInt(`${fontSize}px Prompt`, 10) + 40,
          20
        );
      } else {
        ctx.roundRect(width - 30, yTop, textWidth + 60, parseInt(`${fontSize}px Prompt`, 10) + 40, 20);
      }
      ctx.fill();
    }
    ctx.font = `${fontSize}px Prompt`; // Set the font size and family
    ctx.fillStyle = 'white'; // Set the text color
    if (position === 'left') {
      ctx.fillText(text, (width - textWidth) / 2, lineheight + yTop);
    } else {
      ctx.fillText(text, width, lineheight + yTop);
    }
  };

  const scanFillTextFormat = (ctx, text, width, height, yTop, fontSize, lineheight, isBorder) => {
    const textWidth = calculateTextWidth(text, `${fontSize}px Prompt`);
    const inputString = `${fontSize} Prompt`;
    const numericPart = inputString.replace(/[^0-9]/g, '').trim();
    if (isBorder) {
      ctx.fillStyle = '#000000db';
      ctx.beginPath();
      ctx.roundRect((width - textWidth) / 2 - 30, yTop, textWidth + 55, parseInt(numericPart, 10) + 40, 20);
      ctx.fill();
    }
    ctx.font = `${fontSize}px Prompt`; // Set the font size and family
    ctx.fillStyle = 'white'; // Set the text color
    ctx.fillText(text, (width - textWidth) / 2, lineheight + yTop);
  };

  const drawWithTheme = (canvasElement, ctx, tbl) => {
    // console.log(themeName)
    if (backgroundImage) ctx.drawImage(backgroundImage, 0, 0, width, height);
    if (themeName === '10 x 15 ซม.') {
      textFormat(ctx, auth?.userInfo.shop?.th, width, height, 460, 90, 90, true);
      textFormat(
        ctx,
        props.data?.tableName ? props.data.tableName : `${tbl?.name?.th}${tbl?.name?.en && ` : ${tbl?.name?.en}`}`,
        width,
        height,
        630,
        70,
        80,
        true
      );
      scanFillTextFormat(ctx, 'Scan to Order', width, height, 1460, 60, 70, true);
      textFormat(ctx, 'สแกนเพื่อสั่งอาหาร', width, height, 1600, 60, 70, true);
      textFormat(ctx, 'Powered by www.ez-order.co', width, height, 1730, 30, 40, false);
      // const canvasWidth = 250; // Set your desired canvas width
      // const canvasHeight = (Logo.height * canvasWidth) / Logo.width;
      // const canvasWidth = 250; // Set your desired canvas width
      const canvasHeight = 250;
      const aspectRatio = logo.width / logo.height;
      let newWidth = 0;
      let newHeight = 0;

      // Portrait-oriented image
      newWidth = canvasHeight * aspectRatio;
      newHeight = canvasHeight;
      const x = (width - newWidth) / 2;
      const y = height / 2;
      ctx.drawImage(logo, x, 160, newWidth, newHeight);
      const qrcode = document.getElementById(`canvas_${props.data?.link ? props.data.link : tbl.qrcode[0].code}`);
      ctx.drawImage(qrcode, (width - 450) / 2, y - 0, 450, 450);
    } else if (themeName === '12 x 7.5 ซม.') {
      // const canvasWidth = 200; // Set your desired canvas width
      const canvasHeight = 200;
      const aspectRatio = logo.width / logo.height;
      let newWidth = 0;
      let newHeight = 0;

      // Portrait-oriented image
      newWidth = canvasHeight * aspectRatio;
      newHeight = canvasHeight;

      // const x = (width - newWidth) / 2;
      const y = height / 2;

      ctx.drawImage(logo, (width / 2 - newWidth) / 2, 160, newWidth, newHeight);
      textFormat2(ctx, auth?.userInfo.shop?.th, width / 2, height, canvasHeight + 165 + 30, 40, 50, true, 'left');
      textFormat2(
        ctx,
        props.data?.fromTable?.name?.th ? props.data?.fromTable?.name.th : tbl.name.th,
        width / 2,
        height,
        y + (tbl?.name?.en || props.data?.fromTable?.name?.en ? 50 : 70),
        90,
        100,
        true,
        'left'
      );
      if (props.data?.fromTable?.name?.en && props.data?.fromTable?.name?.en !== '') {
        textFormat2(ctx, props.data.fromTable.name.en, width / 2, height, y + 190, 90, 90, true, 'left');
      } else if (tbl?.name?.en) {
        textFormat2(ctx, tbl?.name?.en, width / 2, height, y + 160, 90, 100, true, 'left');
      }
      textFormat2(ctx, 'Scan to Order', width / 2 + (width / 2 - 260) / 2, height, 630, 40, 50, true, 'right');
      textFormat2(ctx, 'สแกนเพื่อสั่งอาหาร', width / 2 + (width / 2 - 300) / 2, height, 730, 40, 50, true, 'right');
      textFormat2(ctx, 'Powered by www.ez-order.co', width / 2, height, 790, 25, 35, true, 'left');
      const qrcode = document.getElementById(`canvas_${props.data?.link ? props.data.link : tbl.qrcode[0].code}`);
      ctx.drawImage(qrcode, width / 2 + (width / 2 - 380) / 2, 100, 400, 400);
    } else if (themeName === '10 x 10 ซม.') {
      const canvasHeight = 200;
      const aspectRatio = logo.width / logo.height;
      let newWidth = 0;
      let newHeight = 0;

      // Portrait-oriented image
      newWidth = canvasHeight * aspectRatio;
      newHeight = canvasHeight;
      const x = (width - newWidth) / 2;
      const y = height / 2;
      const heightGroup = 30;
      ctx.drawImage(logo, x, heightGroup, newWidth, newHeight);
      textFormat(ctx, auth?.userInfo.shop?.th, width, height, canvasHeight + heightGroup + 30, 50, 60, true);
      textFormat(
        ctx,
        !tbl ? props.data.tableName : `${tbl.name.th}${tbl?.name?.en && ` : ${tbl.name.en}`}`,
        width,
        height,
        canvasHeight + heightGroup + 30 + 60 + 60,
        50,
        60,
        true
      );
      scanFillTextFormat(ctx, 'Scan to Order', width, height, height - 330, 60, 70, true);
      textFormat(ctx, 'สแกนเพื่อสั่งอาหาร', width, height, height - 200, 60, 70, true);
      textFormat(ctx, 'Powered by www.ez-order.co', width, height, height - 70, 30, 40, false);
      const qrcode = document.getElementById(`canvas_${props.data?.link ? props.data.link : tbl.qrcode[0].code}`);
      ctx.drawImage(qrcode, (width - 300) / 2, (y + 400) / 2, 300, 300);
    } else {
      const qrcode = document.getElementById(`canvas_${props.data?.link ? props.data.link : tbl.qrcode[0].code}`);
      ctx.drawImage(qrcode, 0, 0, 512, 512);
    }
    return canvasElement;
  };

  useEffect(() => {
    const createCanvas = async () => {
      const canvasElement = document.createElement('canvas');
      canvasElement.width = width;
      canvasElement.height = height;
      const ctx = canvasElement.getContext('2d');
      // Fisrt Load Font
      ctx.font = `25px Prompt`;
      const font = new FontFace('Prompt', 'url(/assets/fonts/Prompt-Bold.ttf)');
      font.load().then((loadedFont) => {
        document.fonts.add(loadedFont);
        if (props.downloadAll) {
          if (props.selectDownload) {
            setTimeout(async () => {
              const promiseState = tables.map((tbl, i) => {
                const canvas = drawWithTheme(canvasElement, ctx, tbl);
                const canvasSelect = canvas.toDataURL('image/png').split(',')[1];
                zip.file(`#${i + 1}_${tbl.name.th}.png`, canvasSelect, { base64: true });
                return zip;
              });

              Promise.all(promiseState).then(() => {
                zip
                  .generateAsync({ type: 'blob' }, (metadata) => {
                    // setProgress(`progression: ${metadata.percent.toFixed(2)} %`);
                    setProgress(metadata.percent);
                    if (metadata.currentFile) {
                      setCurrentProgress(`file:${metadata.currentFile}`);
                    }
                  })
                  .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `qrcode_${Date.now()}.zip`;
                    a.click();
                    enqueueSnackbar('ดาวน์โหลดสำเร็จ', {
                      variant: 'success',
                      anchorOrigin: { vertical: 'top', horizontal: 'right' },
                      autoHideDuration: 1000,
                    });
                    props.setIsLoading(false);
                    props.setSelectDownload(false);
                    URL.revokeObjectURL(url);
                    setProgress(0);
                    setCurrentProgress('');
                  });
                // setCanvasDownload(canvasTheme[0]);
                // setSelectDownload(false)
              });
            }, 500);
          }

          // else {
          //   canvasTheme = drawWithTheme(canvasElement, ctx, tables[0])
          //   setCanvasDownload(canvasTheme);
          //   setSelectDownload(false)
          // }
        } else {
          const canvasTheme = drawWithTheme(canvasElement, ctx, '');
          props.setCanvasDownload(canvasTheme);
          props.setSelectDownload(false);
        }
        // Now you can use the font for rendering
      });

      // setTimeout(() => {
      //   setIsLoading(false)
      //   setIsLoadingButton(false)
      // }, 500);
    };
    createCanvas();
  }, [backgroundImage, props.selectDownload]);
  const convertSizeObject = (sizeObject) => {
    if (!sizeObject || !sizeObject.size || !sizeObject.url) {
      console.error('Invalid size object. It should have "size" and "url" properties.');
      return null;
    }
    const cleanedSize = sizeObject.size.trim();
    const sizeMatch = cleanedSize.match(/(\d+(?:\.\d+)?)\s*x\s*(\d+(?:\.\d+)?)\s*ซม\./);
    if (!sizeMatch) {
      console.error('Invalid size format. It should be in the format "width x height ซม.".');
      return null;
    }
    const width = sizeMatch[1];
    const height = sizeMatch[2];

    return `${parseInt(width, 10)}x${height}`;
  };
  useEffect(() => {
    handleSizeChange({ size: 'default' }, { size: 'default', url: '' });
    const logoMe = new Image();
    if (auth?.userInfo?.branch[0].logo_image) {
      logoMe.src = auth?.userInfo?.branch[0].logo_image;
    } else {
      logoMe.src = '../assets/images/theme/logo-theme-ezorder.jpg';
    }
    logoMe.crossOrigin = 'anonymous';
    logoMe.onload = () => setLogo(logoMe);
  }, []);

  const handleSizeChange = (option, selectedSize) => {
    if (selectedSize?.size) {
      setThemeName(selectedSize.size);
      setSelectedSize({
        size: option,
        url: selectedSize.url || '', // Set the URL from newSizeItem or an empty string if not available
      });
      props.setIsLoading(true);
      switch (selectedSize.size) {
        case '10 x 15 ซม.': {
          // 10*15 cm
          setWidth(1200);
          setHeight(1800);
          const image = new Image();
          image.src = `../assets/images/preview/10x15/${selectedSize.url}`;
          image.onload = () => setBackgroundImage(image);
          setBackgroundTheme(`../assets/images/mock_preview/10x15/${selectedSize.url}`);
          setTextPosition({ x: width / 2, y: height / 2 });
          props.setIsLoading(false);
          break;
        }
        case '10 x 10 ซม.': {
          // 10*10 cm
          setWidth(1181);
          setHeight(1181);
          const image = new Image();
          image.src = `../assets/images/preview/${convertSizeObject(selectedSize)}/${selectedSize.url}`;
          image.onload = () => setBackgroundImage(image);
          setBackgroundTheme(`../assets/images/mock_preview/${convertSizeObject(selectedSize)}/${selectedSize.url}`);
          setTextPosition({ x: width * 0.8, y: height * 0.8 });
          props.setIsLoading(false);
          break;
        }
        case '12 x 7.5 ซม.': {
          // 12*7.5 cm
          setWidth(1417);
          setHeight(885);
          const image = new Image();
          image.src = `../assets/images/preview/12x7.5/${selectedSize.url}`;
          image.onload = () => setBackgroundImage(image);
          setBackgroundTheme(`../assets/images/mock_preview/12x7.5/${selectedSize.url}`);
          setTextPosition({ x: width / 4, y: height / 4 });
          props.setIsLoading(false);
          break;
        }
        default: // qr only
          setWidth(1181);
          setHeight(1181);
          setBackgroundTheme('');
          props.setIsLoading(false);
          break;
      }
    } else {
      handleSizeChange({ size: 'default' }, { size: 'default', url: '' });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        position: 'relative',
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="role-label"
          id="categoryTheme"
          name="categoryTheme"
          value={categoryTheme}
          onChange={(e) => handleThemeBackgroundChange(e.target.value)}
        >
          <MenuItem value={'default'}>ประเภท Theme </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.name} value={category.name}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Select
          labelId="size-label"
          id="categorySize"
          name="categorySize"
          value={selectedSize.size}
          onChange={(e) => {
            const newSize = e.target.value;
            const newSizeItem = categories
              .find((category) => category.name === categoryTheme)
              ?.sizes.find((sizeItem) => sizeItem.size === newSize);
            handleSizeChange(e.target.value, newSizeItem);
          }}
        >
          <MenuItem value={'default'}>ขนาด </MenuItem>
          {/* Assuming you want to display sizes of the selected category */}
          {categories
            .find((category) => category.name === categoryTheme)
            ?.sizes.map((sizeItem, i) => (
              <MenuItem key={sizeItem.size} value={sizeItem.size}>
                {sizeItem.size}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {props.data?.link ? (
        <QRCodeCanvas
          hidden
          id={`canvas_${props.data?.link}`}
          size={512}
          value={props.data?.link}
          includeMargin
          imageSettings={{
            src: '/assets/illustrations/ezorder_login.png',
            x: undefined,
            y: undefined,
            height: 80,
            width: 80,
            excavate: false,
          }}
        />
      ) : (
        tables.map((tbl) => (
          <QRCodeCanvas
            key={tbl.qrcode[0].code}
            hidden
            id={`canvas_${tbl.qrcode[0].code}`}
            size={512}
            value={`${frontendURL}/qrcode/${tbl.qrcode[0].code}`}
            includeMargin
            imageSettings={{
              src: '/assets/illustrations/ezorder_login.png',
              x: undefined,
              y: undefined,
              height: 80,
              width: 80,
              excavate: false,
            }}
          />
        ))
      )}
      {progress > 0 && (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={progress} currentProgress={currentProgress} />
        </Box>
      )}
      <>
        {themeName === 'default' ? (
          <QRCodeCanvas
            size={256}
            value={'ตรวจสอบคิวอาร์แบบจำลอง'}
            includeMargin
            style={{ filter: props.isLoading ? 'blur(5px)' : 'none' }}
            imageSettings={{
              src: '/assets/illustrations/ezorder_login.png',
              x: undefined,
              y: undefined,
              height: 48,
              width: 48,
              excavate: false,
            }}
          />
        ) : (
          <img src={backgroundTheme} alt="theme-preview" style={{ filter: props.isLoading ? 'blur(5px)' : 'none' }} />
        )}

        {props.isLoading && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%', position: 'absolute', zIndex: 9999999, height: 'calc(100%)', left: 0, top: 0 }}
          >
            <CircularProgress size={100} />
          </Stack>
        )}
      </>
    </div>
  );
};

Canvas.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  selectDownload: PropTypes.bool.isRequired,
  setSelectDownload: PropTypes.func.isRequired,
  downloadAll: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setCanvasDownload: PropTypes.func.isRequired,
};

export default Canvas;
