import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

// import '../../style/prodCreate.css';

import * as yup from 'yup';
import _ from 'lodash';

// @mui
import {
  FormControlLabel,
  InputAdornment,
  Checkbox,
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  List,
  CardHeader,
  Button,
  Stack,
  ListItem,
  Switch,
  ListItemText,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// redux
import { listCategory } from '../../redux/actions/categoryActions';
import { updatePackage, detailPackage } from '../../redux/actions/packageActions';
import Iconify from '../../components/iconify';
import ModalAttribute from '../../components/modal/attribute';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale="2"
      maxLength={8}
    />
  );
});

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อแพ็กเกจ (2-150 ตัวอักษร)')
      .max(150, 'ชื่อแพ็กเกจเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อแพ็กเกจ'),
    en: yup.string().min(2, 'โปรดระบุชื่อแพ็กเกจ (2-150 ตัวอักษร)').max(150, 'ชื่อแพ็กเกจเกินจำนวนที่สามารถรองรับ'),
    // zh: yup.string()
    //   .min(2, 'โปรดระบุชื่อแพ็กเกจ (2-100 ตัวอักษร)')
    //   .max(100, 'ชื่อแพ็กเกจเกินจำนวนที่สามารถรองรับ')
    //   .required('โปรดระบุชื่อแพ็กเกจ'),
  }),
  description: yup.object().shape({
    th: yup.string().max(300, 'รายละเอียดสินค้าเกินจำนวนที่สามารถรองรับ'),
    en: yup.string().max(300, 'รายละเอียดสินค้าเกินจำนวนที่สามารถรองรับ'),
  }),
  // category: yup.string().required('โปรดเลือกประเภทแพ็กเกจ'),
  period_duration: yup.number().label('โปรดระบุเป็นตัวเลข'),
  price: yup.number().required('โปรดระบุราคา'),
  kids_price: yup.number().required('โปรดระบุราคา'),
  buffet_lists: yup.array().min(1, 'โปรดระบุรายการอาหารในแพ็กเกจ').required('โปรดระบุรายการอาหารในแพ็กเกจ')
});

export default function Edit() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.auth);
  const { packageState, error, loading } = useSelector((state) => state.package);
  const { attributes } = useSelector((state) => state.attribute);
  const { options } = useSelector((state) => state.option);
  const { categories } = useSelector((state) => state.category);
  const [productList, setProductList] = React.useState([]);

  useEffect(() => {
    if (branch) {
      dispatch(detailPackage({ packageId: id }));
      // dispatch(listCategory())
    }
  }, [branch, dispatch]);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(listCategory())
      // console.log("useEffect",categories)
    }
  },[branch, packageState])

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      description: {},
      attributes: [],
      price: '',
      kids_price: '',
      buffet_lists: [],
      period_duration: '',
      is_enable: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newAttributes = [];
        values.attributes.forEach((item) => {
          newAttributes.push(item._id);
        });
        const newBuffetLists = _.map(values.buffet_lists, '_id');
        const data = {
          name: values.name,
          description: values.description,
          // category: values.category,
          attributes: newAttributes,
          price: values.price,
          kids_price: values.kids_price,
          period_duration: values.period_duration,
          buffet_lists: newBuffetLists,
          is_enable: values.is_enable,
        };

        await dispatch(updatePackage({ packageId: id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return;
        }
        enqueueSnackbar('แก้ไขข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/package');
      } catch (error) {
        enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });
  const [expanded, setExpanded] = useState();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    formik.setFieldValue('name.th', packageState.name?.th || '');
    formik.setFieldValue('description.th', packageState.description?.th || '');
    formik.setFieldValue('name.en', packageState.name?.en || '');
    formik.setFieldValue('description.en', packageState.description?.en || '');

    if (packageState.name?.en || packageState.description?.en) setExpanded('panel1');

    formik.setFieldValue('attributes', packageState.attributes || []);
    formik.setFieldValue('price', packageState.price || '');
    formik.setFieldValue('kids_price', packageState.kids_price || 0);

    formik.setFieldValue('period_duration', packageState.period_duration || '');
    formik.setFieldValue('buffet_lists', packageState.buffet_lists || []);
    formik.setFieldValue('is_enable', packageState.is_enable);
    // const newState = []
    // const newCategories = categories.filter((element) => element.productCount.length > 0 && element.is_recommend_category === false)

    // // eslint-disable-next-line array-callback-return
    // newCategories.map((cate) => {
    //   const children = packageState.buffet_lists.filter((list) => list.category === cate._id)
    //   newState.push({
    //     parent: cate._id,
    //     name: cate.name,
    //     // checked: false,
    //     children,
    //     productCount: cate.productCount,
    //     totalChildren: cate.productCount.length
    //   })
    // })

    // setProductList(newState);
    const newState = []
    // console.log(categories,packageState.buffet_lists)
    let newCategories = []
    // console.log(packageState,categories)
    const packageUse = packageState
    if(categories.length !== 0 && Object.keys(packageState).length !== 0){
      newCategories = categories.filter((element) => element.productCount.length > 0 && element.is_recommend_category === false)
      newCategories.map((cate) => {
          // console.log(packageUse.buffet_lists)
          const children = packageUse.buffet_lists.filter((list) => list.category === cate._id)
          newState.push({
            parent: cate._id,
            name: cate.name,
            // checked: false,
            children,
            productCount: cate.productCount,
            totalChildren: cate.productCount.length
          })
          return newState
        })
      setProductList(newState);
    }
  }, [packageState, loading,categories]);


  const handleBack = () => {
    navigate('/package');
  };

  const [openAttribute, setOpenAttribute] = useState(false);
  const [attributeState, setAttributeState] = useState([]);

  const handleCloseAttribute = () => {
    setOpenAttribute(false);
    setAttributeState([]);
  };

  const handleOpenAttribute = (value) => {
    setAttributeState(value);
    setOpenAttribute(true);
  };



  const handleChange1 = (event, cate) => {
    const newState = []
    newState.push(...productList)
    const index = newState.findIndex(b => b?.parent === event.target.value)
    newState[index].children = event.target.checked ? cate.productCount : []
    newState[index].checked = event.target.checked

    const buffetLists = []
    newState.filter((n) => n.children.length > 0).map((state) => buffetLists.push(...state.children))
    formik.setFieldValue('buffet_lists', buffetLists);
    setProductList(newState)
  };

  const handleChange2 = (event, parent, product) => {
    const newState = []
    newState.push(...productList)
    const findParent = newState.find(p => p?.parent === parent.parent)
    if (event.target.checked) {
      findParent.children.push(product)
    } else {
      const remove = findParent.children.filter((c) => c._id !== event.target.value)
      findParent.children = remove.length === 0 ? [] : remove
    }

    const buffetLists = []
    newState.filter((n) => n.children.length > 0).map((state) => buffetLists.push(...state.children))
    formik.setFieldValue('buffet_lists', buffetLists);
    setProductList(newState)
  };

  const children = (parent) => {
    if (parent.productCount?.length > 0) {
      return <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {
          // eslint-disable-next-line arrow-body-style
          parent.productCount.map((product, i) => {
            const checked = parent.children.filter((c) => c._id === product._id)?.length > 0
            let salePrice = product.price;
            if (product.discount) {
              salePrice = parseFloat(product.price) - parseFloat(product.discount);
            }
            return <FormControlLabel
              key={i}
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                  <img src={product?.image_url ? product?.image_url : "../../assets/images/theme/logo-theme-ezorder.jpg"} alt='ezorder' width={20} />
                  <span style={{ fontSize: 14 }}>{product?.name?.th} {salePrice} บาท</span>
                </div>
              }
              control={
                <Checkbox value={product._id} checked={checked} onChange={(e) => handleChange2(e, parent, product)} />
              }
            />
          })
        }

      </Box>
    }
    return false
  }

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | แก้ไขแพ็กเกจ </title>
      </Helmet>

      <Container>
        {!loading && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                แก้ไขแพ็กเกจ
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    defaultChecked={packageState.is_enable}
                    onChange={(e, value) => formik.setFieldValue('is_enable', value || false)}
                  />
                }
                label="แสดงสินค้า"
              />
            </Stack>

            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>

                {/* รายละเอียดแพ็กเกจ (ไทย) */}
                <Grid item xs={12} md={6} lg={12}>
                  <Card>
                    <CardHeader title={'รายละเอียดแพ็กเกจ'} />
                    <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                          (2-150 ตัวอักษร)
                        </Stack>
                        <TextField
                          id="name.th"
                          name="name.th"
                          label="ชื่อแพ็กเกจ *"
                          type="text"
                          value={formik.values.name?.th}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                          helperText={formik.touched.name?.th && formik.errors.name?.th}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                          (0-300 ตัวอักษร)
                        </Stack>
                        <TextField
                          id="description.th"
                          name="description.th"
                          label="รายละเอียด"
                          multiline
                          rows={3}
                          value={formik.values.description?.th}
                          onChange={formik.handleChange}
                          error={formik.touched.description?.th && Boolean(formik.errors.description?.th)}
                          helperText={formik.touched.description?.th && formik.errors.description?.th}
                        />
                      </FormControl>
                    </Box>
                  </Card>
                </Grid>
                {/* รายละเอียดแพ็กเกจ (อังกฤษ) */}

                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                      <AccordionSummary
                        aria-controls="panelEnLang-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="panelEnLang"
                        sx={{ pt: 0, pr: 2, pl: 2, pb: 0 }}
                      >
                        <CardHeader sx={{ p: 1 }} title={'รายละเอียดแพ็กเกจ (อังกฤษ)'} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ pt: 0, pr: 1, pl: 1, pb: 3 }} dir="ltr">
                          <FormControl fullWidth>
                            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                              (2-150 ตัวอักษร)
                            </Stack>
                            <TextField
                              id="name.en"
                              name="name.en"
                              label="ชื่อแพ็กเกจ (อังกฤษ)"
                              type="text"
                              value={formik.values.name?.en}
                              onChange={formik.handleChange}
                              error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                              helperText={formik.touched.name?.en && formik.errors.name?.en}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{ pr: 1, pl: 1, pb: 1 }} dir="ltr">
                          <FormControl fullWidth>
                            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                              (0-300 ตัวอักษร)
                            </Stack>
                            <TextField
                              id="description.en"
                              name="description.en"
                              label="รายละเอียด (อังกฤษ)"
                              multiline
                              rows={3}
                              value={formik.values.description?.en}
                              onChange={formik.handleChange}
                              error={formik.touched.description?.en && Boolean(formik.errors.description?.en)}
                              helperText={formik.touched.description?.en && formik.errors.description?.en}
                            />
                          </FormControl>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </Grid>

                {/* ราคาแพ็กเกจ */}
                <Grid item xs={12} md={6} lg={12}>
                  <Card>
                    <CardHeader title="ราคาแพ็กเกจ" />
                    <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                      <TextField
                        fullWidth
                        placeholder="0.00"
                        label="ราคาผู้ใหญ่ *"
                        name="price"
                        id="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                        }}
                      />
                    </Box>

                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      <TextField
                        fullWidth
                        placeholder="0.00"
                        label="ราคาเด็ก *"
                        name="kids_price"
                        id="kids_price"
                        value={formik.values.kids_price}
                        onChange={formik.handleChange}
                        error={formik.touched.kids_price && Boolean(formik.errors.kids_price)}
                        helperText={formik.touched.kids_price && formik.errors.kids_price}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                        }}
                      />
                    </Box>
                  </Card>
                </Grid>

                {/* ระยะเวลาในการรับประทาน */}
                <Grid item xs={12} md={6} lg={12}>
                  <Card>
                    <CardHeader title="ระยะเวลาในการรับประทานอาหาร (นาที)" />
                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      <TextField
                        fullWidth
                        placeholder="0"
                        label="ระยะเวลา (นาที)"
                        name="period_duration"
                        id="period_duration"
                        value={formik.values.period_duration}
                        onChange={formik.handleChange}
                        error={formik.touched.period_duration && Boolean(formik.errors.period_duration)}
                        helperText={formik.touched.period_duration && formik.errors.period_duration}
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    </Box>
                  </Card>
                </Grid>

                {/* ตัวเลือกแพ็กเกจ */}
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <CardHeader
                      action={
                        <Button
                          fullWidth
                          variant="outlined"
                          size="medium"
                          startIcon={<AddCircleOutlineRoundedIcon />}
                          onClick={() => handleOpenAttribute([])}
                        >
                          เพิ่มตัวเลือก
                        </Button>
                      }
                      title="ตัวเลือกสินค้า"
                    />
                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      <Autocomplete
                        id="attributes"
                        limitTags={3}
                        multiple
                        options={attributes}
                        getOptionLabel={(option) => option.name?.th || ''}
                        onChange={(e, value) => formik.setFieldValue('attributes', value || '')}
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        defaultValue={packageState.attributes}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderInput={(params) => (
                          <TextField {...params} label="ตัวเลือกแพ็กเกจ" placeholder="ตัวเลือกแพ็กเกจ" />
                        )}

                      />
                      <List variant="primary" dense>
                        {formik.values.attributes.length > 0 &&
                          formik.values.attributes.map((attribute, index) => {
                            let showOption = '';
                            attribute.options?.forEach((option) => {
                              const masterOption = options.find((o) => o._id === option._id);
                              if (!masterOption) return;
                              if (showOption !== '') showOption += ',';
                              showOption += masterOption?.name?.th;
                            });
                            return (
                              <Box key={index}>
                                {index > 0 && <Divider />}
                                <ListItem
                                  secondaryAction={
                                    <>
                                      <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => handleOpenAttribute(attribute)}
                                      >
                                        <Iconify icon={'eva:edit-fill'} />
                                      </IconButton>
                                    </>
                                  }
                                >
                                  <ListItemText primary={attribute.name?.th} secondary={showOption} />
                                </ListItem>
                              </Box>
                            );
                          })}
                      </List>
                    </Box>
                  </Card>
                </Grid>

                {/* รายการอาหารที่เข้ารวมแพ็กเกจ */}
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <CardHeader
                      title="รายการอาหารที่เข้ารวมแพ็กเกจ"
                      subheader={
                        <Typography variant="subtitle2" noWrap sx={{ color: 'red' }}>
                          {formik.touched.buffet_lists && formik.errors.buffet_lists}
                        </Typography>

                      }
                    />

                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      {
                        // eslint-disable-next-line arrow-body-style
                        productList.length > 0 && productList.map((element, i) => {
                          // console.log(cat)
                          return (
                            <Accordion sx={{
                              background: '#fff'
                            }} key={i}>
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                expandIcon={<ExpandMoreIcon />}
                                id={`panel${element.parent}a-header`}
                                sx={{
                                  p: 0,
                                  margin: 0,
                                  minHeight: "0px !important",
                                  "& .MuiAccordionSummary-content.Mui-expanded": {
                                    margin: 0,
                                    minHeight: 0
                                  }, "& .MuiAccordionSummary-content": {
                                    margin: 0
                                  }
                                }}
                              >
                                <FormControlLabel
                                  label={`${element?.name?.th} (${element.children.length}/${element.totalChildren})`}
                                  control={
                                    <Checkbox
                                      value={element?.parent}
                                      checked={element.totalChildren === element.children.length}
                                      indeterminate={element.children.length > 0 && element.children.length < element.totalChildren}
                                      onChange={(e) => handleChange1(e, element)}
                                    />
                                  }
                                />
                              </AccordionSummary>
                              <AccordionDetails sx={{
                                border: "1px solid #eee",
                                padding: "5px"
                              }}>
                                {children(element)}
                              </AccordionDetails>
                            </Accordion>
                          )
                        })
                      }
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                    <Button variant="outlined" onClick={() => handleBack()}>
                      ยกเลิก
                    </Button>

                    <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                      บันทึก
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </>
        )}
        {openAttribute && (
          <ModalAttribute openModel={openAttribute} handleClose={() => handleCloseAttribute()} props={attributeState} />
        )}
      </Container>

    </>
  );
}
